import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "dashboard-area product-grid p-bottom-100" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xl-12 col-lg-12 col-md-12 col-sm-12" }
const _hoisted_5 = { class: "container mt-5 mb-5" }
const _hoisted_6 = { class: "d-flex justify-content-center row" }
const _hoisted_7 = {
  class: "col-12",
  ref: "updateProfileRef"
}
const _hoisted_8 = { class: "upload_modules" }
const _hoisted_9 = { class: "modules__title" }
const _hoisted_10 = { class: "modules__content" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_nav = _resolveComponent("top-nav")!
  const _component_bottom_nav = _resolveComponent("bottom-nav")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_top_nav),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("form", {
                    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateProfile && _ctx.updateProfile(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('profilePage.title')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('profilePage.firstName')), 1),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.firstName) = $event)),
                            class: "form-control"
                          }, null, 512), [
                            [_vModelText, _ctx.user.firstName]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('profilePage.lastName')), 1),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.lastName) = $event)),
                            class: "form-control"
                          }, null, 512), [
                            [_vModelText, _ctx.user.lastName]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('profilePage.email')), 1),
                          _withDirectives(_createElementVNode("input", {
                            type: "email",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.email) = $event)),
                            class: "form-control",
                            "aria-describedby": "emailHelp",
                            disabled: ""
                          }, null, 512), [
                            [_vModelText, _ctx.user.email]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('profilePage.country')), 1),
                          _withDirectives(_createElementVNode("select", {
                            class: "form-control",
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.countryID) = $event))
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country, index) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: index,
                                value: country.id
                              }, _toDisplayString(country.name), 9, _hoisted_15))
                            }), 128))
                          ], 512), [
                            [_vModelSelect, _ctx.user.countryID]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('profilePage.title')), 1),
                          _withDirectives(_createElementVNode("input", {
                            type: "tel",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.phoneNumber) = $event)),
                            class: "form-control"
                          }, null, 512), [
                            [_vModelText, _ctx.user.phoneNumber]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("button", _hoisted_17, _toDisplayString(_ctx.$t('profilePage.buttonText')), 1)
                  ], 32)
                ], 512)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_bottom_nav)
  ], 64))
}