

import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {key} from "@/store";
import topNav from "@/components/home/topNav.vue";
import bottomNav from "@/components/home/bottomNav.vue";
import toastr from "toastr";
import {useLoading} from "vue-loading-overlay";
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'UserProfile',
  components: {
    topNav,
    bottomNav,
  },
  setup() {
    const store = useStore(key)
    store.dispatch('country/fetch')
    const updateProfileRef = ref(null)
    const $loading: any = useLoading()
   // const loader = useLoading();
    const state = reactive({})
    const { t } = useI18n()

    const user = computed(() => store.getters['user/get'])
    const countries = computed(() => store.getters['country/getCountries'])


    async function updateProfile() {
      if (!validateField()) {
        return false
      }
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    const loader =  $loading.show({container: updateProfileRef.value})

      const response = await store.dispatch('user/updateProfile', user.value)
      if (response.status) {
        toastr.success(response.message)
      } else {
        toastr.error(response.message)
      }
      loader.hide()
    }

    function validateField() {
      if (!user.value.firstName || !user.value.lastName) {
        toastr.warning(t('profilePage.nameValidation'))
        return false
      } else if (!user.value.phoneNumber) {
        toastr.warning(t('profilePage.phoneValidation'))
        return false
      }
      return true
    }

    return {
      user,
      countries,
      updateProfile,
      updateProfileRef,
      ...toRefs(state)
    }
  },

});
